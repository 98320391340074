import { PublicKey } from '@solana/web3.js';
import { v4 as uuidv4 } from 'uuid';

export class CONSTANTS {
  public static readonly DEFAULT_APPLICATION_NAME: string = 'default';

  public static readonly HNT_TO_USD_CONVERSION_RATE: number = 100_000;
  public static readonly RNDR_TO_USD_CONVERSION_RATE: number = 100_000;

  public static readonly DEFAULT_JUP_SLIPPAGE_BPS: number = 30;

  public static readonly MAX_LINE_ITEM_QUANTITY = 9999;
}

export class IDS {
  public static readonly SPL_NOOP_PROGRAM_ADDRESS: string =
    'noopb9bkMVfRPU8AsbpTUg8AQkHtKwMYZiFUjNRtMmV';
  public static readonly BPF_UPGRADABLE_LOADER_PROGRAM_ADDRESS: string =
    'BPFLoaderUpgradeab1e11111111111111111111111';

  public static readonly MEMO_PROGRAM_ADDRESS: string =
    'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr';

  public static readonly SPL_NOOP_PROGRAM_ID: PublicKey = new PublicKey(
    IDS.SPL_NOOP_PROGRAM_ADDRESS,
  );

  public static readonly MEMO_PROGRAM_ID: PublicKey = new PublicKey(
    IDS.MEMO_PROGRAM_ADDRESS,
  );
  public static readonly BPF_UPGRADABLE_LOADER_PROGRAM_ID: PublicKey =
    new PublicKey(IDS.BPF_UPGRADABLE_LOADER_PROGRAM_ADDRESS);

  public static readonly DEVELOPMENT_SOLANA_PROGRAM_ID: string =
    '4oneBqgBxggE9BebCstNQastoFSBzNB2uzZqnrbgAz1q';
  public static readonly DEVELOPMENT_SOLANA_LUT_ADDRESS: string =
    'FwjSqZbueffHq8ZG3FikgkkS4eqCDzhfMZyKUpgikZT';

  public static readonly STAGING_SOLANA_PROGRAM_ID: string =
    '9Jw6uYqxb5SNNh24dacnxoENjj3zF6UDUGPeXqShr2iJ';
  public static readonly STAGING_SOLANA_LUT_ADDRESS: string =
    '7yGPzeghKFQ6ombQdB3w12kgZGWnxTwoy8jMQwiNrg2x';

  public static readonly PRODUCTION_SOLANA_PROGRAM_ID: string =
    'AYGdvqsQruZoaJPWsViLqUgtbfXGRnxzgxzW4zmbbckL';
  public static readonly PRODUCTION_LUT_ADDRESS: string =
    'DkU1wCgDK3kQjMicecQ6Rg8nFJBrrZk8buSa1uAy24FS';

  // Id Generation
  public static readonly newSphereId = (prefix: string): string => {
    return `${prefix}_${IDS.newUuid().replace(/-/g, '')}`;
  };

  public static readonly newUuid = () => uuidv4();

  public static readonly newNanoId = async (size?: number) =>
    IDS.newUuid().substring(0, size);
}

export class HELIUM {
  public static readonly HNT_MINT_ADDRESS =
    'hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux';
  public static readonly HELIUM_IOT_MINT_ADDRESS =
    'iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns';
  public static readonly HELIUM_MOBILE_MINT_ADDRESS =
    'mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6';
  public static readonly DATACREDITS_MINT_ADDRESS: string =
    'dcuc8Amr83Wz27ZkQ2K9NS6r8zRpf1J6cvArEBDZDmm';
  public static readonly DATACREDITS_PROGRAM_ADDRESS: string =
    'credMBJhYFzfn7NxBMdU4aUqFggAjgztaCcv2Fo6fPT';
  public static readonly SUBDAO_PROGRAM_ADDRESS: string =
    'hdaoVTCqhfHHo75XdAMxBKdUqvq1i5bF23sisBqVgGR';

  public static readonly HNT_MINT_ID: PublicKey = new PublicKey(
    HELIUM.HNT_MINT_ADDRESS,
  );
  public static readonly HELIUM_IOT_MINT_ID: PublicKey = new PublicKey(
    HELIUM.HELIUM_IOT_MINT_ADDRESS,
  );
  public static readonly HELIUM_MOBILE_MINT_ID: PublicKey = new PublicKey(
    HELIUM.HELIUM_MOBILE_MINT_ADDRESS,
  );
  public static readonly DATACREDITS_MINT_ID: PublicKey = new PublicKey(
    HELIUM.DATACREDITS_MINT_ADDRESS,
  );
  public static readonly DATACREDITS_PROGRAM_ID: PublicKey = new PublicKey(
    HELIUM.DATACREDITS_PROGRAM_ADDRESS,
  );
  public static readonly SUBDAO_PROGRAM_ID: PublicKey = new PublicKey(
    HELIUM.SUBDAO_PROGRAM_ADDRESS,
  );

  public static readonly HNT_MINT_DECIMALS = 8;
}

/**
 * Used for api pagination
 */
export const DEFAULT_EVENTS_PER_QUERY = 10;
